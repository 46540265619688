<template>
    <router-link to="/new" class="project-empty-card">
        <div class="project-empty-content">
            <div class="project-empty-plus">+</div>
            <div class="project-empty-text">
                You don’t have any apps yet <br>
                Click to start a new one.
            </div>
        </div>
    </router-link>
</template>

<script>
  export default {
    'name': 'ProjectEmptyCard',
  }
</script>
<style scoped>
.project-empty-plus {
  text-align: center;
  margin-bottom: 30px;
  font-size: 90px;
  line-height: 67px;
}
.project-empty-card {
  border-radius: 12px;
  display: flex;
  margin: 20px;
  height: 282px;
  align-items: center;
  justify-content: center;
  width: 340px;
  border: 3px dashed #BDBDBD;
  color: #BDBDBD;
}
.project-empty-text {
  text-align: center;
}
</style>
