<template>
    <div class="projects" id="projects">
        <ProjectEmptyCard v-if="published_projects.length == 0 && draft_projects.length == 0 && role === ''"></ProjectEmptyCard>
        <div class="project-header" v-if="published_projects.length != 0">Published</div>
        <div class="project-list" v-if="published_projects.length != 0">
            <ProjectCard :onDelete="() => deleteHandler(published_projects, project.id)" :key="project.id" v-for="project in published_projects" :project="project"></ProjectCard>
            <ProjectEmptyCard v-if="role === ''"></ProjectEmptyCard>
        </div>
        <div class="project-header" v-if="draft_projects.length != 0">Draft</div>
       <div class="project-list draft-list" v-if="draft_projects.length != 0">
           <ProjectCard :onDelete="() => deleteHandler(draft_projects, project.id)" :key="project.id" :type="'drifting'" v-for="project in draft_projects" :project="project"></ProjectCard>
           <ProjectEmptyCard v-if="role === ''"></ProjectEmptyCard>
       </div>
      <div class="project-header" v-if="shared_projects.length != 0">Shared projects</div>
      <div class="project-list draft-list" v-if="shared_projects.length != 0">
        <ProjectCard :onDelete="() => console.log('123')" :key="project.id" v-for="project in shared_projects" :project="project"></ProjectCard>
      </div>
    </div>
</template>

<script>
    import ProjectEmptyCard from '@/components/project/ProjectEmptyCard.vue'
    import ProjectCard from '@/components/project/ProjectCard.vue'
    import constants from "../Constants";
  export default {
    name: 'myprojects',
    components: {
      ProjectEmptyCard,ProjectCard
    },
    data: ()=>({
        user_id: '',
        token: '',
      role: '',
      draft_projects: [],
      published_projects: [],
      shared_projects: [],
    }),
    methods: {
      deleteHandler: (projectList, projectId) => {
        for(let i = 0; i < projectList.length; i++) {
          if(projectList[i].id == projectId) {
            projectList.splice(i, 1);
            break;
          }
        }
      }
    },
    mounted() {
      let user = JSON.parse(localStorage.getItem('maagio_user'));
      this.token = localStorage.getItem('token');
      this.user_id = user.uid;
      this.role = user.role;
      let data = {user_id: this.user_id, token: this.token, email: user.email};
      let obj = this;
      $.ajax({
        url         : constants.BACKEND_URL + 'project/get-user-projects',
        type        : 'GET',
        data        : data,
        dataType    : 'json',
        success     : function( respond, status, jqXHR ){
          if(respond.ok === 1) {
            obj.published_projects = respond.published_projects;
            obj.draft_projects = respond.draft_projects;
            obj.shared_projects = respond.shared_projects;
          }
        },

        error: function( jqXHR, status, errorThrown ){

        }
      });
    }
  }
</script>
<style scoped lang="less">
.projects {
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 50px;
  min-height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
}
.project-header {
  font-size: 20px;
  text-transform: uppercase;
  padding-left: 20px;
  padding-bottom: 10px;
  color: #ABADAE;
}
.project-menu-text {
  width: 70%;
  display: flex;
  justify-content: center;
  color: #4F4F4F;
}
.project-list {
  padding-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

</style>
<script setup>
</script>
