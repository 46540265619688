<template>
    <div class="project-card">
        <div class="project-content">
            <div class="image-container">
                <div class="project-image" :style="{ backgroundImage: 'url('+getImgUrl()+')' }"></div>
            </div>
            <div class="project-name">
                {{project.name}}
            </div>
            <div class="project-menu">
              <router-link :to="{path: 'edit', query: {project_id: project.id}}" class="project-menu-text" v-if="type !== 'published'">CONTINUE</router-link>
                <router-link :to="{path: 'edit', query: {project_id: project.id}}" class="project-menu-text"  v-if="type === 'published'">Edit</router-link>
                <div class="menu-delimiter"></div>
                <router-link :to="{path: 'show/'+project.special_link}" v-if="type === 'published'"><div class="project-menu-text">
                    Preview
                    <span class="menu-link"></span>
                </div>
                </router-link>

                <div class="menu-delimiter" v-if="type === 'published'"></div>
                <div class="project-menu-icons">
<!--                    <div class="menu-share"></div>-->
                    <div class="menu-delete" v-if="project.role === 'owner'" @click="deleteProject = true"></div>
                </div>
                <div class="delete-confirmation" v-if="deleteProject === true">
                    Are you sure you want to delete the project?
                    <div class="delete-buttons">
                        <div class="menu-confirm delete-button" @click="removeProject">Yes</div>
                        <div class="menu-decline delete-button" @click="deleteProject = false">No</div>
                    </div>
                </div>
                <div class="delete-process" v-if="deleteProcess === true">
                    The project is being deleted
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import constants from "../../Constants";

  export default {
    'name': 'ProjectCard',
    props: {
      onDelete: {
        type: Function,
        required: true
      },
      project: {
        type: Object,
        required: true
      },
      type: {
        type: String,
        default() {
          return 'published';
        }
      }
    },
    mounted() {
    },
    data: ()=>({
      deleteProject: false,
      deleteProcess: false,
    }),
    methods: {
      getImgUrl() {
        if (this._props.project.project_logo == null) {
          return require('../../assets/img/default_logo.jpg');
        } else {
          return this._props.project.project_logo;
        }
      },
      removeProject(){
        this.deleteProcess = true;
        this.deleteProject = false;
        let user = JSON.parse(localStorage.getItem('maagio_user'));
        this.token = localStorage.getItem('token');
        this.user_id = user.uid;
        let data = { user_id: this.user_id, token: this.token, project_id: this.project.id };
        let obj = this;
        $.ajax({
          url: constants.BACKEND_URL + 'project/delete',
          type: 'POST',
          data: data,
          dataType: 'json',
          success: (respond, status, jqXHR) => {
            obj.deleteProcess = false;
            if (respond.ok === 1) {
              this.onDelete(obj.project.id)
            }
          },
          error: function (jqXHR, status, errorThrown) {
            obj.deleteProcess = false;
            obj.deleteProject = false;
          }
        });
      },
    },
  }
</script>
<style scoped lang="less">
.project-card {
  -webkit-transition: box-shadow .3s;
  -moz-transition: box-shadow .3s;
  -ms-transition: box-shadow .3s;
  -o-transition: box-shadow .3s;
  transition: box-shadow .3s;
  padding: 30px 30px;
  margin: 20px;
  height: 282px;
  width: 340px;
  border: 3px solid #BDBDBD;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  & .project-content {
    & .image-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      & .project-image {
        width: 90%;
        height: 110px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    & .project-menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 20px;
      position: relative;
      .delete-process {
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        background-color: white;
        align-items: center;
        justify-content: center;
        color: red;
        text-align: center;
        padding-top: 10px;
      }
      .delete-confirmation {
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 5px;
        font-size: 14px;
        color: red;
        .delete-buttons {
          display: flex;
          padding-top: 10px;
          .delete-button.menu-decline {
            background-color: #2f80ed;
            color: white;
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            border-radius: 6px;
          }
          .delete-button.menu-confirm {
            color: red;
            border: 1px solid red;
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            border-radius: 6px;
          }
          .delete-button {
            margin-left: 10px;
            margin-right: 10px;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 5px;
            padding-bottom: 5px;
            cursor: pointer;
          }
        }
      }
      & .project-menu-text:hover .menu-link {
        background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2223%22%20height%3D%2223%22%20viewBox%3D%220%200%2023%2023%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%3Cpath%20d%3D%22M20%2020H2.5V2.5H11.25V0H2.5C1.1125%200%200%201.125%200%202.5V20C0%2021.375%201.1125%2022.5%202.5%2022.5H20C21.375%2022.5%2022.5%2021.375%2022.5%2020V11.25H20V20ZM13.75%200V2.5H18.2375L5.95%2014.7875L7.7125%2016.55L20%204.2625V8.75H22.5V0H13.75Z%22%20fill%3D%22%232f80ed%22/%3E%0A%3C/svg%3E%0A');
      }
      & .project-menu-text:hover {
        color: #2f80ed;
      }
      & .project-menu-text {
        transition: all .3s;
        display: flex;
        cursor: pointer;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 6px;
        color: #BDBDBD;
      }
      & .menu-delimiter {
        width: 2px;
        height: 20px;
        background-color: #bababa;
      }
      & .menu-link {
        transition: all .3s;
        background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2223%22%20height%3D%2223%22%20viewBox%3D%220%200%2023%2023%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%3Cpath%20d%3D%22M20%2020H2.5V2.5H11.25V0H2.5C1.1125%200%200%201.125%200%202.5V20C0%2021.375%201.1125%2022.5%202.5%2022.5H20C21.375%2022.5%2022.5%2021.375%2022.5%2020V11.25H20V20ZM13.75%200V2.5H18.2375L5.95%2014.7875L7.7125%2016.55L20%204.2625V8.75H22.5V0H13.75Z%22%20fill%3D%22%23BDBDBD%22/%3E%0A%3C/svg%3E%0A');
        background-repeat: no-repeat;
        width: 16px;
        height: 16px;
        margin-left: 6px;
        display: inline-block;
        background-size: contain;
        cursor: pointer;
      }
      & .project-menu-icons {
        margin-left: 10px;
        & .menu-share {
          background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2223%22%20height%3D%2226%22%20viewBox%3D%220%200%2023%2026%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%3Cpath%20d%3D%22M18.75%2018.1C17.8%2018.1%2016.95%2018.475%2016.3%2019.0625L7.3875%2013.875C7.45%2013.5875%207.5%2013.3%207.5%2013C7.5%2012.7%207.45%2012.4125%207.3875%2012.125L16.2%206.9875C16.875%207.6125%2017.7625%208%2018.75%208C20.825%208%2022.5%206.325%2022.5%204.25C22.5%202.175%2020.825%200.5%2018.75%200.5C16.675%200.5%2015%202.175%2015%204.25C15%204.55%2015.05%204.8375%2015.1125%205.125L6.3%2010.2625C5.625%209.6375%204.7375%209.25%203.75%209.25C1.675%209.25%200%2010.925%200%2013C0%2015.075%201.675%2016.75%203.75%2016.75C4.7375%2016.75%205.625%2016.3625%206.3%2015.7375L15.2%2020.9375C15.1375%2021.2%2015.1%2021.475%2015.1%2021.75C15.1%2023.7625%2016.7375%2025.4%2018.75%2025.4C20.7625%2025.4%2022.4%2023.7625%2022.4%2021.75C22.4%2019.7375%2020.7625%2018.1%2018.75%2018.1Z%22%20fill%3D%22%23BDBDBD%22/%3E%0A%3C/svg%3E%0A');
          background-repeat: no-repeat;
          width: 16px;
          height: 16px;
          display: inline-block;
          background-size: contain;
          cursor: pointer;
        }
        & .menu-delete {
          background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2218%22%20height%3D%2224%22%20viewBox%3D%220%200%2018%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%3Cpath%20d%3D%22M1.75%2020.75C1.75%2022.125%202.875%2023.25%204.25%2023.25H14.25C15.625%2023.25%2016.75%2022.125%2016.75%2020.75V5.75H1.75V20.75ZM4.25%208.25H14.25V20.75H4.25V8.25ZM13.625%202L12.375%200.75H6.125L4.875%202H0.5V4.5H18V2H13.625Z%22%20fill%3D%22%23BDBDBD%22/%3E%0A%3C/svg%3E%0A');
          background-repeat: no-repeat;
          width: 16px;
          height: 16px;
          margin-left: 18px;
          display: inline-block;
          background-size: contain;
          cursor: pointer;
        }
      }
    }
  }
}
.project-name {
  font-weight: 400;
  color: #BDBDBD;
  font-size: 22px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #828282;
}
</style>
